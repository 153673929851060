import Prod1 from '../images/photo_kotlet.jpg';

const OurProduction = () => {
    return (
      <>
        <div className="py-1 md:pt-44 pt-20 bg-bg2 relative" > 
        <div id="production" className="absolute top-20 left-0"></div>
        <div id="productionMobile" className="absolute top-8 left-0"></div>
          <h2 className="my-2 text-center md:text-6xl text-3xl text-green-900 font-bold">Наше производство</h2>
            <div className='flex justify-center'>
              <div className='md:w-96 border-b-4 w-44 border-green-900 my-2'></div>
            </div>
            <p className="leading-relaxed text-center md:text-2xl text-md font-semibold text-gray-500 md:w-2/3 md:mx-auto mx-2">
            Многолетний опыт и лучшие специалисты позволили нам создать эффективное автоматизированное производство. Это подтверждается пройденными аудитами крупнейших сетей магазинов.

            {/* Многолетний опыт и лучшие специалисты позволили создать нам высокоэффективное производство которое полностью автоматизировано через систему 1С. Это повреждают пройденные аудиты у крупнейших сетей магазинов.  */}
          </p>
        </div>
      <section className="w-full bg-bg2 md:px-16 px-4  md:pt-12 pt-6">


        <div className="w-full md:px-16 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 gap-4 justify-stretch w-full mx-auto ">
          <div className="justify-self-end">
          <div className='group transition duration-700 ease-in-out scale-100 hover:scale-100'>
              <img src={Prod1} alt="img27" className='rounded-xl md:h-[470px] object-cover shadow-lg'/>
              <div className="text-center leading-none flex justify-center rounded-t-xl absolute top-0 w-full py-4 bg-green-900/80">
                <h2 className="md:text-4xl text-white font-thin text-xl px-2 py-16">
                  Наше предприятие расположено в Москве <br/>
                  <span className="md:text-2xl text-white font-thin text-lg px-2 py-2"> 
                    Текущая загрузка производственных мощностей: 35 %
                  </span>
                </h2>
              </div>
            </div>
          </div>
        <div className="justify-self-start">
          <div className=" grid grid-cols-2 grid-rows-2 md:gap-4 gap-2 text-center">
              <div className="transition duration-300 bg-card text-green-900 hover:bg-green-900 hover:text-card md:rounded-3xl rounded-lg group md:h-[225px] h-[130px] flex flex-col shadow-lg justify-evenly md:p-4">
                <h1 className="font-semibold md:text-5xl text-2xl text-center relative z-10">
                  2500 кв.м.
                </h1>
                <h2 className=" font-medium md:text-xl text-md mx-4">
                  Площадь помещений       
                </h2>
              </div>

              <div className="transition duration-300 bg-card text-green-900 hover:bg-green-900 hover:text-card md:rounded-3xl rounded-lg group md:h-[225px] h-[130px] flex flex-col shadow-lg justify-evenly md:p-4">
                <h1 className="font-semibold md:text-5xl text-2xl text-center relative z-10">
                  750 т/мес
                </h1>
                <h2 className=" font-medium md:text-xl text-md mx-4">
                  Производственные мощности      
                </h2>
              </div>

              <div className="transition duration-300 bg-card text-green-900 hover:bg-green-900 hover:text-card md:rounded-3xl rounded-lg group md:h-[225px] h-[130px] flex flex-col shadow-lg justify-evenly md:p-4">
                <h1 className="font-semibold md:text-5xl text-2xl text-center relative z-10">
                  260 SKU
                </h1>
                <h2 className=" font-medium md:text-xl text-md mx-4">
                  Широкий ассортимент      
                </h2> 
              </div>
   
              <div className="transition duration-300 bg-card text-green-900 hover:bg-green-900 hover:text-card md:rounded-3xl rounded-lg group md:h-[225px] h-[130px] flex flex-col shadow-lg justify-evenly md:p-4">
                <h1 className="font-semibold md:text-5xl text-2xl text-center relative z-10">
                  ХАССП
                </h1>
                <h2 className=" font-medium md:text-xl text-md mx-4">
                  Внедрена на предприятии        
                </h2>
              </div>

          </div>
        </div>
      </div>

      </div>
      </section>
      </>
    );
  };

  export default OurProduction;