import React from 'react';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Services from '../components/Services';
import NavBar from '../components/Navbar/NavBar';
import Ticker_My from '../components/Ticker';
import OurProduction from '../components/OurProduction';

const Home = () => {
    return (
        <>
            <NavBar />
            <Hero/>
            <OurProduction/>
            <Ticker_My/>
            <Cta/>
            <Services />
            <Footer />
        </>
    )
}

export default Home;

