import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Cta = () => {
    return ( 
        <div className="w-full flex items-center justify-center bg-bg2 pt-4 text-white cta" id='cta'>
            <div className="mx-8 w-full h-96 text-center lg:text-left md:py-16 py-4 md:px-12 flex lg:justify-between items-center">                    
                <div className="w-full flex flex-col lg:flex-row lg:justify-around">
                    <div className="mb-4">
                        <p className='text-lg md:text-5xl font-bold mb-4'>Вы уже готовы работать с нами?</p>
                        <p className="text-lg md:text-3xl">Свяжитесь с нами и давайте начнем взаимовыгодное сотрудничество <span className='font-black'>сегодня!</span></p>
                    </div>
                    
                    <div className="w-full lg:w-64 pt-6 lg:mx-12">
                        <HashLink 
                        smooth to="/contact#contact"
                        className="bg-transparent border hover:bg-green-900 text-2xl hover:border-green-800 text-white justify-center text-center rounded-lg px-10 py-3 flex items-center group"
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                        >
                            Написать нам
                            <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </HashLink>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Cta;