import React from 'react';
import { HashLink } from 'react-router-hash-link';



const NavLinks = () => {

    return (
        <>
            <HashLink 
            className="px-2 md:text-xl font-extrabold text-gray-500 hover:text-green-900" 
            to="/#hero"
            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            >
                О нас
            </HashLink>

            <HashLink 
            className="px-2 md:text-xl font-extrabold text-gray-500 hover:text-green-900" 
            to="/#products"
            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            >
                Продукция
            </HashLink>
            
            <HashLink 
            className="px-2 md:text-xl font-extrabold text-gray-500 hover:text-green-900" 
            to="/#production"
            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            >
                Производство
            </HashLink>

            <HashLink
            className="px-2 md:text-xl font-extrabold text-gray-500 hover:text-green-900"
            to="/#clients"
            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start'})}
            >
                Клиенты
            </HashLink>

            <HashLink
            className="px-2 md:text-xl font-extrabold text-gray-500 hover:text-green-900"
            to="/#services"
            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start'})}
            >
                Преимущества
            </HashLink>

            {/* Link to next page */}
            <HashLink 
            className="text-white  md:text-xl bg-green-900 hover:bg-green-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" 
            to="/contact#contact"
            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            >
                Написать нам
            </HashLink>
        </>
    )
}

export default NavLinks;
