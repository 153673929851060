import React from 'react';
import IconHand from '../images/icon_hand.png';
import IconGmo from '../images/icon_gmo.png';
import IconProduct from '../images/icon_product.png';
import IconTime from '../images/icon_speedtime.png';


const  Services = () => {
    return (
        <div  className="bg-bg2 md:pt-44 py-20 relative">
            <div id="services" className="absolute top-28 left-0"></div>
            <div id="servicesMobile" className="absolute top-4 left-0"></div>
            <section>
                <div className=" bg-bg2 text-center md:py-4">
                    <h3 className="text-3xl text-green-900 md:text-6xl font-bold mx-auto md:py-4 py-4">
                        Наши преимущества
                    </h3>   
                    <div className='flex justify-center'>
                        <div className='md:w-80 w-44 border-b-4 border-green-900 my-3'></div>
                    </div>
                    <p className="leading-relaxed text-center md:text-3xl text-xl font-semibold text-gray-500 my-2">
                    Являемся надежным поставщиком уже более десяти лет
                    </p>
                </div>

                    <div className="mx-auto md:w-4/5 md:py-12   ">
                        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 md:gap-2">
                            
                            <div className=" transition-all ease-in-out duration-400 overflow-hidden md:p-3 group">
                                <div className="md:m-2 m-1 text-center">
                                    <img alt="card img" className="rounded-t md:group-hover:animate-bounce transition duration-1000 ease-in-out mx-auto md:h-[115px] h-[75px]" src={IconHand} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-green-900">Отборное сырьё</h2>
                                    <p className="md:text-lg text-sm font-normal text-gray-500">
                                        Для своей продукции мы отбираем только лучшее сырьё, закупая напрямую от производителей, и тщательно котролируем все этапы производства.
                                    </p>
                                </div>
                            </div>

                            <div className=" transition-all ease-in-out duration-400 overflow-hidden md:p-3 group">
                                <div className="md:m-2 m-1 text-center">
                                    <img alt="card img" className="rounded-t md:group-hover:animate-bounce transition duration-1000 ease-in-out mx-auto md:h-[115px] h-[75px]" src={IconGmo} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-green-900">Без ГМО</h2>
                                    <p className="md:text-lg text-sm font-normal text-gray-500">
                                        Мы производим исключительно натуральную продуцию без использования ГМО.
                                    </p>
                                </div>
                            </div>

                            <div className=" transition-all ease-in-out duration-400 overflow-hidden md:p-3 group">
                                <div className="md:m-2 m-1 text-center">
                                    <img alt="card img" className="rounded-t md:group-hover:animate-bounce transition duration-1000 ease-in-out mx-auto md:h-[100px] h-[70px]" src={IconProduct} />
                                    <h2 className="font-semibold my-4 text-2xl text-center md:pt-[15px] pt-[5px] text-green-900">Большой ассортимент</h2>
                                    <p className="md:text-lg text-sm font-normal text-gray-500">
                                        {/* Мы производим более 180 наименований продукции в самой большой группе и постоянно занимаемся расширением линейки производства. */}
                                        Мы производим более 260 наименований продукции, и постоянно занимаемся расширением ассортиментной линейки.
                                    </p>
                                </div>
                            </div>

                            <div className=" transition-all ease-in-out duration-400 overflow-hidden md:p-3 group">
                                <div className="md:m-2 m-1 text-center">
                                    <img alt="card img" className="rounded-t md:group-hover:animate-bounce transition duration-1000 ease-in-out mx-auto md:h-[100px] h-[70px]" src={IconTime} />
                                    <h2 className="font-semibold my-4 text-2xl text-center md:pt-[15px] pt-[5px] text-green-900">Быстрота и удобство</h2>
                                    <p className="md:text-lg text-sm font-normal text-gray-500">
                                        Наш уровень сервиса - 99.4%. <br/> Скорость предоставления образцов под технические задания сетей магазинов - не более трех дней.
                                    </p>
                                </div>
                            </div> 
                        </div>
                    </div>
            </section>
        </div>
    )
}

export default Services;