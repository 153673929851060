import React from 'react';
// import {Link} from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';
// import { FaPhoneSquareAlt } from "react-icons/fa";
import { IoIosMailUnread } from "react-icons/io";
import { FaBusinessTime } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";

import FermaImg from '../images/ferma_for_footer.png';

const Footer = () => {
    return (
        <>
        <footer className="bg-bg1 relative">
        <div className="container mx-auto mx:py-[5rem] py-[2rem]">
          {/* footer div all */}
          <div className="flex justify-between flex-col md:flex-row  items-center md:items-center md:gap-2 text-left">
            {/* IMG side */}
            <div className="flex flex-col md:w-1/3 md:p-0 md:px-8 gap-3 mx-2">
              <img
                src={FermaImg}
                alt="Ferma_Img_grey"
                className="w-full"
              />
            </div>
            {/* middle div */}
            <div className="flex flex-col gap-4 md:w-2/3 relative">
              <p className="text-[22px] font-bold footer-main text-green-900">Контакты</p>

              <span className="top-[33px] absolute w-[5rem] h-[4px] bg-green-900"></span>

              {/* <div className="flex flex-row mx-1">
              <a href="tel:+79857633879">
                <FaPhoneSquareAlt  className='text-green-900 ' fontSize="1.5em" />
              </a>
                <p className="text-[16px] hover:text-green-900 cursor-pointer text-[#646464] font-medium hover:font-bold mx-4">
                  +7 (985) 763-38-79
                </p>
              </div> */}

              <div className="flex flex-row mx-1">
              <a href="mailto:d.chikisheva@sferm.ru">
                <IoIosMailUnread className='text-green-900 ' fontSize="1.5em" />
              </a>
                <p className="text-[16px] hover:text-green-900 cursor-pointer text-[#646464] font-medium hover:font-bold mx-4">
                d.chikisheva@sferm.ru
                </p>
              </div>

              <div className="flex flex-row mx-1">
                <FaBusinessTime className='text-green-900 ' fontSize="1.5em" />
                <p className="text-[16px] hover:text-green-900 cursor-pointer text-[#646464] font-medium hover:font-bold mx-4">
                Пн-Пт: с 10.00 до 18.00<br/>
                Сб-Вс: выходной
                </p>
              </div>

              <div className="flex flex-row mx-1">
                <FaLocationDot className='text-green-900 ' fontSize="1.5em" />
                <p className="text-[16px] hover:text-green-900 cursor-pointer text-[#646464] font-medium hover:font-bold mx-4">
                г.Москва, улица Бусиновская Горка, дом 2
                </p>
              </div>
            </div>
            {/* right div */}
            {/* <div className="flex flex-col md:w-1/3 gap-4 relative mx-5 w-full h-full"> */}
            {/* <p className="text-[22px] font-bold footer-main text-green-900">О нас</p>

            <span className="top-[33px] absolute w-[3rem] h-[4px] bg-green-900"></span>
            <p className="mx:text-[17px] text-[15px] font-medium text-[#646464]">
                Мы гарантируем взаимовыгодное сотрудничество и производство качественных полуфабрикатов без задержек в нужных объемах.
              </p> */}
              {/* <p className="mx:text-[16px] text-[11px] font-medium text-[#646464] text-right absolute right-0 bottom-96">
              ООО “Фермерский супермаркет” © {new Date().getFullYear()} | Все права защищены. <br />
                {" "}
                Сайт сделал - {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://shevelev.info/"
                >
                  Шевелёв Игорь
                </a> 
              </p>
            </div> */}
          </div>
        </div>
        <p className="mx:text-[16px] text-[11px] font-medium text-[#646464] text-right absolute right-0 bottom-0">
              ООО “Фермерский супермаркет” © {new Date().getFullYear()} | Все права защищены. <br />
                {" "}
                Сайт сделал - {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://shevelev.info/"
                >
                  Шевелёв Игорь
                </a> 
              </p>
      </footer>
        </>
    )
}
export default Footer;
