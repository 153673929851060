import Kotleti from '../images/Kotleti.JPG';
import Marinad from '../images/Marinad.JPG';
import Kotleti_Back from '../images/Kotleti_Back.png';
import Marinad_Back from '../images/Marinad_Back.png';
import './ProductionCardStyle.css';

const ProdictuonCard = () => {
    return (
    <div className="flex flex-col sm:flex-row items-center justify-center gap-8 md:gap-32">
        {/* // Card 1 */}
        <div className="flip-card">
            <div className="w-[300px] h-[300px] md:w-[550px] md:h-[550px]">
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                    <div className="absolute rounded-xl">
                            <img src={Kotleti} alt="img27" className='w-full rounded-3xl shadow-lg'/>
                            <div className="text-center mt-2 flex justify-center absolute md:top-40 top-20 w-full py-4">
                                <h2 className="md:text-7xl text-white font-thin text-4xl px-8 py-2">
                                    Из <span className='font-bold'> рубленного </span> мяса 
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="flip-card-back">
                    <div className="absolute rounded-xl">
                                <img src={Kotleti_Back} alt="img27" className='w-full rounded-3xl shadow-lg'/>
                                <div className="text-center mt-2 flex justify-center absolute md:top-44 top-20 w-full py-4">
                                    <h2 className="md:text-7xl text-white font-thin text-5xl px-8 "> 
                                        <span className='font-bold'> 180 </span> <br/> SKU 
                                    </h2>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        {/* // Card 2 */}
        <div className="flip-card">
            <div className="w-[300px] h-[300px] md:w-[550px] md:h-[550px]">
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                    <div className="absolute rounded-xl">
                            <img src={Marinad} alt="img27" className='w-full rounded-3xl shadow-lg'/>
                            <div className="text-center mt-2 flex justify-center absolute md:top-40 top-20 w-full py-4">
                                <h2 className="md:text-7xl text-white font-thin text-4xl px-8 py-2">
                                    <span className='font-bold'> Маринадная </span> группа 
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="flip-card-back">
                    <div className="absolute rounded-xl">
                                <img src={Marinad_Back} alt="img27" className='w-full rounded-3xl shadow-lg'/>
                                <div className="text-center mt-2 flex justify-center absolute md:top-44 top-20 w-full py-4">
                                    <h2 className="md:text-7xl text-white font-thin text-5xl px-8 "> 
                                        <span className='font-bold'> 100 </span> <br/> SKU 
                                    </h2>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }

export default ProdictuonCard;