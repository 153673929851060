import React, { useState, useRef} from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Notiflix from 'notiflix';

const Contact = () => {
    const form = useRef();

    useDocTitle('Фермерский сепермаркет | Поставка полуфабрикатов сетевым магазинам')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])
    const [sumbited, setSubmited] = useState(false);

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        document.getElementById('input_first_name').value="";
        document.getElementById('input_last_name').value="";
        document.getElementById('input_email').value="";
        document.getElementById('input_phone').value="";
        document.getElementById('input_message').value="";
        firstName.setFirstName('');
        lastName.setLastName('');
        email.setEmail('');
        phone.setPhone('');
        message.setMessage('');
    }

    const sendEmail = (e) => {
        e.preventDefault();
        setSubmited(true);

        emailjs
        .sendForm('service_ferma', 'template_kf827de', form.current, {
          publicKey: 'fUq6r0EZfh6XNqis3',
        })
        .then(  
          () => {
            console.log('SUCCESS!');
            clearInput();
            setSubmited(true);

          },
          (error) => {
            clearInput();
            setSubmited(true);
            console.log('FAILED...', error.text);
          },
        );

        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type':  'multipart/form-data'
            }
        })
        .then(function (response) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'Отправить письмо';
            //handle success
            Notiflix.Report.success(
                'Success',
                response.data.message,
                'Okay',
            );
        })
        .catch(function (error) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'Ваше письмо успешно дошло до наc';
            //handle error
            const { response } = error;
            if(response.status === 500) {
                Notiflix.Report.failure(
                    'При отправке произошла ошибка',
                    response.data.message,
                    'Okay',
                );
            }
            if(response.data.errors !== null) {
                setErrors(response.data.errors)
            }
        });


    }
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id="contact" className="flex justify-center items-center w-full bg-bg2 py-4 lg:py-20">
                <div className="container mx-auto my-4 mt-4 md:px-4 px-2 lg:px-40">
                {sumbited ? (
                    <div className="py-4 my-2 md:px-4 lg:mx-auto rounded-2xl shadow-2xl w-full bg-bg1 bg-contactImg text-left md:min-h-[500px]">
                        <div className="isolate rounded-xl bg-card/50 shadow-lg ring-1 ring-black/5 md:p-12 p-4 m-4 mt-4 mb-60">
                        <h1 className="md:text-8xl text-center text-2xl font-bold text-green-900">
                        Спасибо за вашу заявку 
                        </h1>
                        <p className="leading-relaxed text-center md:text-5xl text-md font-semibold text-gray-500 mt-2">
                        мы свями свяжемся в ближайшее время.
                        </p>
                        </div>
                    </div>
                ) : (                
                    <form ref={form} onSubmit={sendEmail}>
                    <div className="w-full bg-bg1 py-4 my-2 md:px-14 mr-auto rounded-2xl shadow-2xl md:min-h-[500px]">
                        <div className="flex flex-row">
                            <h1 className="font-bold text-center lg:text-left text-green-900 uppercase md:text-4xl text-2xl pt-3 pl-3">Свяжитесь с нами</h1>
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mt-2 px-3 mx-2">
                                <div>
                                    <input 
                                        id='input_first_name'
                                        name="first_name" 
                                        className="md:w-full w-5/6 mx-2 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline "
                                        type="text" 
                                        placeholder="Имя*" 
                                        value={firstName}
                                        onChange={(e)=> setFirstName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    }
                                </div>
                                
                                <div>
                                    <input 
                                        id='input_last_name'
                                        name="last_name" 
                                        className="md:w-full w-5/6 mx-2 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="Фамилия*"
                                        value={lastName}
                                        onChange={(e)=> setLastName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.last_name}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        id='input_email'
                                        name="email"
                                        className="md:w-full w-5/6 mx-2 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email" 
                                        placeholder="Email*"
                                        value={email}
                                        onChange={(e)=> setEmail(e.target.value)}
                                        onKeyUp={clearErrors}   
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.email}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        id='input_phone_number'
                                        name="phone_number" 
                                        className="md:w-full w-5/6 mx-2 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="tel"
                                        placeholder="Телефон*"
                                        value={phone}
                                        onChange={(e)=> setPhone(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.phone_number}</p>
                                    }
                                </div>

                                < div className="md:col-span-2">
                                <textarea 
                                        name="message" 
                                        placeholder="Письмо*" 
                                        className="md:w-full w-5/6 mx-2 md:h-60 h-40 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        value={message}
                                        onChange={(e)=> setMessage(e.target.value)}
                                        onKeyUp={clearErrors}
                                    ></textarea>
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.message}</p>
                                    }
                                </div>

                                
                                <div className="md:col-span-2">
                                    <button type="submit" id="submitBtn" className="uppercase text-sm font-bold tracking-wide bg-green-900 hover:bg-stone-500 text-gray-100 p-3 rounded-lg md:w-full w-5/6 mx-2 
                                            focus:outline-none focus:shadow-outline">
                                        Отправить письмо
                                    </button>
                                </div>
                        </div>
                </div>
                </form>
                )}
                {/* <div className="w-full lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-green-900 rounded-2xl">
                    <div className="flex flex-col text-white">
                        <div className="flex my-2 w-full">
                                <div className="flex flex-col">
                                        <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                    </div>
                                    <div className="flex flex-col">
                                        <h2 className="md:text-2xl text-xl">Адрес производства</h2>
                                        <p className="text-gray-400">г.Москва, улица Бусиновская Горка, дом 2</p>
                                    </div>
                                </div>
                    
                    <div className="flex my-4 w-full w-1/2">
                        <div className="flex flex-col"> 
                            <i className="fas fa-phone-alt pt-2 pr-2" />
                        </div>
                        <div className="flex flex-col">
                            <h2 className="md:text-2xl text-xl">Позвонить нам</h2>
                            <p className="text-gray-400">+7 (985) 763-38-79</p>
                            <div className='mt-5'>
                                <h2 className="md:text-2xl text-xl">Отправить письмо</h2>
                                <p className="text-gray-400">ferma-supermarket@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex my-4 w-full">
                        <p>Свяжитесь с нами и мы ответим на все ваши вопросы!</p>
                    </div>
                    </div>
                </div> */}

                </div>
            </div>                
            <Footer />
        </>


    )
}

export default Contact;