import React from 'react';
import Ticker from 'framer-motion-ticker';
import Logo1 from '../images/run_run.png';

function Ticker_My() {
  const squareData = [
    {
      id: 1,
      src: Logo1,
    },
  ]

  return (
    <div className=" bg-bg2 text-center md:py-4 py-2 relative md:pt-44 pt-20">
      <div id="clients" className="absolute top-24 left-0"></div>
      <div id="clientsMobile" className="absolute top-8 left-0"></div>
      <h3 className="text-3xl text-green-900 md:text-6xl font-bold mx-auto pt-2 py-2">
        Наши клиенты
      </h3>
      <div className='flex justify-center'>
        <div className='md:w-96 w-44 border-b-4 border-green-900 md:mb-4 mb-2 md:py-2 py-1'></div>
      </div>
        <Ticker duration={30} direction={1}>
        {squareData.map((squareData) => (
          <div
          key={squareData.id}
            style={{
              backgroundImage: `url(${squareData.src})`,
              backgroundSize: 'cover',
              height: '25px',
              width: '875px',
            }}
            className='my-2 md:scale-150 md:mx-[215px] md:mb-16' 
          />
          ))}
        </Ticker>
      </div>
  );
}

export default Ticker_My;